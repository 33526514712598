import React from 'react';
import { Modal, Grid, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './ModalTeamMat';

const ModalTeam = ({open, closeModal, img, nombre, puesto, texto}) =>{

    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={closeModal}
            style={{margin:'0 0  -0.5rem 0'}}
        >   
            <>
                <CloseIcon className={classes.closeModalButton} onClick={closeModal} fontSize="inherit"/>
                <div className={classes.mainDivModal}>      
                    <Grid
                        container
                        direction="row"
                        alignItems="stretch"
                        width="100%"
                    >
                        <Grid item xs={12} md={6}>
                            <img alt="" src={img} className={classes.imgModalTeam}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.divTextModalTeam}>
                                <Typography variant="h5" className={classes.textNombreModalTeam}> 
                                    {nombre}
                                </Typography>
                                <Typography variant="h6"  className={classes.textModalTeam} > 
                                    {puesto} 
                                </Typography>
                                <Typography variant="body1"  className={classes.textModalTeam} > 
                                    {texto} 
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </>
        </Modal>
    )
}

export default ModalTeam;