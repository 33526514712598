import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Grid, Typography, Button} from '@mui/material';
import useStyles from './ClientCardMat';

const ClientCard = ({img, logo, text, detail1, detail2, styleLogo}) => {

    const classes = useStyles();

    const styleImgClientDefault = {width:'100%', height:'100%', objectFit:'cover'};
    const styleLogoDefault = {maxWidth:'70%', maxHeight:'90%', objectFit:'contain'};

    return(
        <Grid
            width="100%"
            container
            direction="row"
            // alignItems="stretch"
        >
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                <div className={classes.imgGrid}>
                    <img alt="" src={img} style={styleImgClientDefault} />
                </div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid} order={{ xs: 2, md: 1 }}>
                <div className={classes.vanaDivFlex}>
                        <div className={classes.logoSection}>
                            <img alt="" src={logo} style={styleLogo ? styleLogo : styleLogoDefault} />
                        </div>

                        <div className={classes.textSection}>
                            <Typography variant="h6" className={classes.text}>
                                {text}
                            </Typography>
                        </div>
                        <div className={classes.detailsSection}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                width="80%"
                            >
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                                <Grid item xs={6} className={classes.detailGrid}>
                                    <Typography variant="body1" className={classes.textVanaSection2}>
                                        {detail2}
                                    </Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                                <Grid item xs={6} style={{margin: '1em 0 1em 0'}} className={classes.detailGrid}>
                                    <Typography variant="body1" className={classes.textThirdSection2}>
                                        {detail1}
                                    </Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                            </Grid>
                        </div>
                        <div className={classes.buttonSection}>
                            <Button
                                component={Link}
                                to={'investments'}
                                key={'contact-us-home'}
                                className={classes.buttonLearnMore}
                            >
                                Learn More
                            </Button>
                        </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default ClientCard;