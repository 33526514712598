import { createTheme, responsiveFontSizes} from "@mui/material/styles";

const themeCustom = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11, 
    h2:{
      fontWeight:'bold'
    },
    button: {
      textTransform:'none',
      fontSize:'1rem',
      fontWeight:'bold',
    },
  },
  shape:{
    borderRadius: '0',
  },
  palette: {
    info: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#ff',
    },
  }
});


export const theme = responsiveFontSizes(themeCustom)