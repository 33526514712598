import React from 'react';
import { Avatar, Modal, Typography} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import geaLogo from '../../../assets/gea-logo-white.png';

const ModalLoading = ({loading}) =>(
    <Modal
        open={loading}
        style={{display:"flex", justifyContent:"center", alignItems:"center", flexFlow:'column'}}
    >   
        <>
            <img src={geaLogo} />
            <CircularProgress style={{width:'69px',height:'69px', color:"white", marginTop:'1.5rem'}} />
        </>
    </Modal>
)

export default ModalLoading;