import { createStyles, makeStyles } from '@mui/styles';
import headerBackground from '../../assets/header-background-aboutus.webp';

const useStyles = makeStyles( theme =>
  createStyles({
    gradientDiv : {
        position:'relative',
        top:'0',
        right:'0',
        backgroundImage: `radial-gradient(50% 50% at 50% 50%, rgba(0, 130, 202, 0.375) 0%, rgba(2, 47, 72, 0.5625) 100%), url(${headerBackground})`,
        width: '100%',
        display:'flex',
        justifyContent:'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    imgDiv:{
        position:'absolute',
        top:'0',
        right:'0',
        width: '100%',
        objectFit: 'contain',
        overflowX: 'hidden',
    },
    firstSectionTitle : {
        width: '80%',
        color: 'white',
        [theme.breakpoints.down("md")]:{
            width: '90%', 
        }
    },
    buttonContactUs : {
        margin: '1em 0 6em 0',
        color: 'white',
        backgroundColor: ' rgba(255, 255, 255, 0.35)',
    },

    secondSectionTitle:{
        textAlign: 'justify',
        width: '90%',
        color: 'black',
        fontWeight: 'bold',
        lineHeight: '54px', 
        [theme.breakpoints.down("md")]:{
            textAlign: 'left',
            fontSize: '1.25rem',
            lineHeight: '1.5', 
        }
    },


    containerThirdSection:{
        marginBottom:'3rem',
        [theme.breakpoints.down("md")]:{
            padding:'0', 
        }
    },

    divFlex:{
        display:'flex', 
        alignItems:'center',
        backgroundColor:'white',
        width:"100%",
        height:'100%'
    },
    
    thirdSectionTitle:{
        textAlign: 'justify',
        color: '#54565A',
        fontWeight: '600',
        lineHeight: '36px',
        [theme.breakpoints.down("md")]:{
            margin:'0 1.5em 0 1.5em', 
        }
    },

    thirdSectionImg:{
        width:'100%',
        height:'100%',
        boxShadow: '0.75em 0.75em 0.75em rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down("md")]:{
            width: '100vw',
            boxShadow: '0 0.75em 0.75em rgba(0, 0, 0, 0.25)',
        }
    },

    fourthSectionContainer:{
        textAlign:'center',
        marginTop: '3em',
        marginBottom: '3em',
    },

    imgTeam:{
        objectFit: 'contain',
        width:'100%',
        boxShadow: '0.75em 0.75em 0.75em rgba(0, 0, 0, 0.25)',
    },
    divTeam:{
        display:'flex',
        position:'relative'
    },
    nombrePuestoDivTeam:{
        position:'absolute',
        margin:'0 0 1.5em 1em',
        left:0,
        bottom:0,
        textAlign:'left'
    },
    nombreTeam:{
        color:'white',
        fontWeight: 600,
        lineHeight: '26px'
    },
    puestoTeam:{
        color:'white',
        fontWeight: 'bold'
    },
    titleTeam:{
        color:'black',
        fontWeight: 'bold',
        margin:'0 0 0.75em 0',
    }
  })
);

export default useStyles;