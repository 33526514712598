import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import {theme} from './themeConfig';
import NavBar from './components/NavBar/NavBar';
import ScrollOnTop from './components/ScrollOnTop/ScrollOnTop';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUs/AboutUs';
import Investments from './components/Investments/Investments';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

const pages = [
  {
      name:'Home',
      to: ''
  },
  {
      name:'About Us',
      to: 'aboutus'
  }, 
  {
      name:'Investments',
      to: 'investments'
  }, 
  {
      name:'Contact Us',
      to:'contact'
  }
];

const App = () =>
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <NavBar pages={pages}/>
        <ScrollOnTop>
          <Routes>
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/investments" element={<Investments/>} />
            <Route exact path="/aboutus" element={<AboutUs/>} />
            <Route path="/" element={<Home/>} />
          </Routes>
        </ScrollOnTop>
      <Footer pages={pages}/>
    </BrowserRouter>
  </ThemeProvider>


export default App;

