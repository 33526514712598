import imgVana from '../../assets/imgVana.webp';
import titleVana from '../../assets/titleVana.webp';
import imgZavvie from '../../assets/imgZavvie.webp';
import titleZavvie from '../../assets/titleZavvie.webp';
import imgLiff from '../../assets/imgLiif.webp';
import titleLiff from '../../assets/titleLiif.webp';
import imgBio from '../../assets/imgBio.webp';
import titleBio from '../../assets/titleBio.webp';

const clients = [
    {
        img: imgVana,
        logo: titleVana,
        text: "Located in Guatemala City. Vana offers AI driven micro-loan credits for the financially underserved in Latin America.",
        detail1: "Fintech",
        detail2: <>$1,650,000 <br/>Lead Investor in Series Seed</>,
        styleLogo: {maxHeight:'40%', objectFit:'contain'}
    },
    {
        img: imgZavvie,
        logo: titleZavvie,
        text: "The first brokerage-branded marketplace platform for all selling solutions.",
        detail1: "Proptech & Fintech",
        detail2: <>$1,750,000 <br/>Lead Investor in Series A</>,
        styleLogo: {maxHeight:'100%', objectFit:'contain'}
    },
    {
        img: imgLiff,
        logo: titleLiff,
        text: "Guatemala’s first loan marketplace.",
        detail1: "Fintech",
        detail2: <>$1,250,000 <br/>Leading investor in Series Seed</>
    },
    {
        img: imgBio,
        logo: titleBio,
        text: "A biotech company transforming organic waste into clean energy.",
        detail1: "Biotech",
        detail2: <>Undisclosed Valuation  <br/>Lead Investor</>
    }
]

export default clients;