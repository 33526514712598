import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { Grid, Typography } from '@mui/material';
import geaLogo from '../../../../assets/gea-logo.png';
import geaLogoLight from '../../../../assets/gea-logo-white.png';

const styleButton = {
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#54565A',
    marginTop: '0.75rem'
}

const getIcon = (icon) => {
    switch(icon){
        case 'aboutus': return <InfoOutlinedIcon />;
        case 'contact': return <PersonOutlineOutlinedIcon/>;
        case 'investments': return <LocalAtmOutlinedIcon/>;
        case '': return <HomeOutlinedIcon />;
        default: return <HomeOutlinedIcon />
    }
}

const MenuList = ({pages, closeMenu}) => (
    <Box
      sx={{width : 250}}
      role="presentation"
      onClick={closeMenu}
      onKeyDown={closeMenu}
    >   
        <div style={{display:'flex', justifyContent:"center"}}>
            <img alt="" src={geaLogo} height="38px" style={{margin:'0.75rem'}}/>
        </div>
        <Divider />
        <List>
            {pages.map((page, index) => (
            <ListItem component={Link} key={page.name} style={styleButton} to={page.to} >
                <ListItemIcon>
                    {getIcon(page.to)}
                </ListItemIcon>
                <ListItemText 
                    primary={<Typography variant="h5" style={{fontWeight:'bold'}}>{page.name}</Typography>}
                />
            </ListItem>
            ))}
        </List>
      
    </Box>
  );


const MobileBox = ({pages, light}) => {
    
    const [open,setOpen] = useState(false);
    
    const navigate = useNavigate();

    return(
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Grid container >
                <Grid item xs={6} display="flex" alignItems={"center"} justifyContent="center" >
                    <img 
                        alt="" src={light ? geaLogoLight : geaLogo} 
                        height="50px" 
                        style={{cursor:'pointer'}}
                        onClick={() => navigate('/')}
                    />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="flex-end"> 
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => setOpen(true)}
                        color="secondary"
                        style={{alignSelf:'right', marginRight:'25%'}}
                    >
                        <MenuIcon fontSize="large" style={{color: light ? 'white' :"black", fontSize:'2.5rem'}}/>
                    </IconButton>
                </Grid>
            </Grid>

            <Drawer
                anchor={'left'}
                open={open}
                onClose={() => setOpen(false)}
                style={{overflowY:'hidden'}}
            >
                <MenuList pages={pages} light={light} closeMenu={() => setOpen(false)}/>
            </Drawer>
        </Box>
    )
}

export default MobileBox;