import { createStyles, makeStyles } from '@mui/styles';
import footerImgBackground from '../../assets/footer-img-background.webp';
const useStyles = makeStyles( theme =>
  createStyles({

    firstSectionDiv:{
        margin: '0 0 0 0',
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 130, 202, 0.5) 100%), url(${footerImgBackground})`,
        backdropFilter: 'blur(120px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    footerText:{
        color:'white'
    },
    buttonContactUs : {
        color: 'white',
        backgroundColor: ' rgba(255, 255, 255, 0.35)',
        [theme.breakpoints.down("md")]:{
            marginTop:'1.5em'
        }
    },
    gridItemCenter:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    secondSectionDiv:{
        background:'#1D1D1B'
    },
    gridItemCenterColumn:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonFooter:{
        textAlign: 'center',
        color: 'white',
        textTransform: 'none',
        fontSize: '17px',
        fontWeight: '400'
    },
    geaLogoFooter:{
        maxHeight:'105px',
        width: '100%',
        objectFit: 'contain'
    }
  })
)

export default useStyles;