import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {Typography} from '@mui/material';
import useStyles from './TeamMat';
import ModalTeam from './ModalTeam/ModalTeam';

const NombrePuesto = ({classes, nombre, puesto}) =>(
    <div className={classes.nombrePuestoDivTeam}>
        <Typography variant="h6" className={classes.nombreTeam} > 
            {nombre}
        </Typography>
        <Typography variant="body1" className={classes.puestoTeam} > 
            {puesto} 
        </Typography>
    </div>
)

const Team = ({img, nombre, puesto, texto}) =>{

    const classes = useStyles()

    const [hoverEnter, setHoverEnter] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const filterStyle = hoverEnter ? {filter:'brightness(40%)'} : {};

    return(
        <>
            <div 
                className={classes.divTeam} 
                onMouseOver={()=> setHoverEnter(true)}
                onMouseOut={()=> setHoverEnter(false)}
                onClick={()=> setOpenModal(true)}
            >
                <img alt="" src={img} className={classes.imgTeam} style={filterStyle}/>
                {
                    !hoverEnter  ?
                    <NombrePuesto classes={classes} nombre={nombre} puesto={puesto} />
                    :
                    <SearchIcon className={classes.iconSearchTeam} fontSize="inherit"/>
                }
    
            </div>

            <ModalTeam 
                classes={classes}
                open={openModal}
                closeModal={()=> setOpenModal(false)}
                puesto={puesto}
                nombre={nombre}
                img={img}
                texto={texto}
            />
        </>
    )
}

export default Team;