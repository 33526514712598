import { createStyles, makeStyles } from '@mui/styles';
import {imgGridHeight} from './Cards/ClientCardMat';

const useStyles = makeStyles( theme =>
  createStyles({
        containerFirstSection:{
            [theme.breakpoints.down("md")]:{
                padding: '0',
            }
        },
        firstSection :{
            display:'flex',
            position:'relative',
            marginTop:'9rem',
            [theme.breakpoints.down("md")]:{
                padding: '0 0.5rem 0 2rem',
            }
        },
        buttonContactUs : {
            color: 'black',
            backgroundColor: 'rgba(0, 130, 202, 0.5)',
        },
        firstSectionTitle : {
            marginTop: '1.5em',
            maxWidth: '70%',
            [theme.breakpoints.down("md")]:{
                maxWidth: '100%',
            }
        },
        gradientDiv : {
            zIndex:'-1',
            position:'absolute',
            top:'0',
            right:'0',
            height: '100%',
            background: 'radial-gradient(87.68% 87.68% at 49.93% 12.32%, rgba(230, 0, 126, 0.8) 0%, rgba(233, 85, 16, 0.608) 82.18%)',
            width: '65%',
            [theme.breakpoints.down("md")]:{
                width: '100%',
            }
        },
        
        textSecondSection: {
            fontStyle: 'normal',
            fontWeight: '600',
            margin:'0',
            color: '#000101',
            [theme.breakpoints.up("md")]:{
                textAlign: 'justify',
            }
        },
        buttonAboutUs : {
            fontWeight: 'bold',
            textAlign: 'center',
            textDecoration: 'underline',
            color: '#0082CA',
            padding: '0',
        },

        secondSection:{
            margin:'3rem 3rem 0 3rem',
            display:'flex', 
            justifyContent:'center',
        },
        tetris2:{
            width:"60%",
            [theme.breakpoints.down("md")]:{
                width:'8%', 
            }
        },
        gradientDivBackground :{
            position:'absolute', 
            width:'25%', 
            height:'100%', 
            top:'0', 
            right:'0', 
            background:'linear-gradient(90deg, rgba(0, 0, 0, 0.15) 10.65%, rgba(254, 254, 254, 0) 100%)',
            [theme.breakpoints.down("md")]:{
                width:'calc(100% + 3rem)', 
                height:'110%', 
                margin: '0 -1.5rem 0 -1.5rem',
                background:'linear-gradient(0deg,rgba(0, 0, 0, 0.15) 10.65%, rgba(254, 254, 254, 0) 100%)',
            }
        },

        thirdSection:{
            margin:'3em 0 0 0',
            padding: '3em 0 3em 0',
            width:'100vw',
            height:'100%', 
            background: 'linear-gradient(111.33deg, rgba(36, 43, 62, 0.91) 16.26%, rgba(27, 80, 228, 0.72) 75.74%)'
        },

  })
)

export default useStyles;