import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Button} from '@mui/material';
import Box from '@mui/material/Box';
import useStyles from './Homemat';
import tetris from '../../assets/tetris.png';
import tetris2 from '../../assets/tetris2.png';
import carouselSettings from './CarouselSettings.jsx';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Carousel } from 'react-responsive-carousel';
import clients from "./Clients.jsx";
import ClientCard from "./Cards/ClientCards";

const FirstSection = ({classes}) =>(
    <Container maxWidth="lg" className={classes.containerFirstSection}>
        <div className={classes.firstSection}>
            <div>
                <Typography variant="h2" className={classes.firstSectionTitle}> 
                    Gea Capital is an active regional investor based in Guatemala City.
                </Typography>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{marginTop:"1.5rem",padding:'0 10% 10% 0'}}
                >
                    <Grid item xs={6} >
                        <Button
                            component={Link}
                            to={'contact'}
                            key={'contact-us-home'}
                            variant={'contained'}
                            className={classes.buttonContactUs}
                        >
                            Contact Us
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={3} />
                    <Grid item xs={6} md={3} style={{display:'flex',justifyContent:'flex-end'}}>
                        <img alt="" src={tetris} width={'50%'}></img>
                    </Grid>
                </Grid> 
            </div> 
            <div className={classes.gradientDiv}/>
        </div>
    </Container>
)

const SecondSection = ({classes}) =>( 
    <Container maxWidth="lg">
        <div className={classes.secondSection}>
            <Grid
                container
                direction="row"
                alignItems="center"
            >   
                <Box component={Grid} item xs={12} md={1} display={{ xs: "none", md: "block", textAlign:"center" }}>
                    <img alt="" src={tetris2} className={classes.tetris2}/>
                </Box>
                <Grid item xs={11} display={{ xs: "none", md: "block" }}/>
                <Grid item xs={1} display={{ xs: "none", md: "block" }}/>
                <Grid item xs={12} md={10} style={{position:'relative'}}>
                    <Typography variant="h5" className={classes.textSecondSection}>
                        We identify great founders with disruptive technologies and together strive to solve our region’s most pressing needs and generate significant value to society.
                    </Typography>
                    <Button
                        component={Link}
                        variant='text'
                        to={'aboutus'}
                        key={'about-us-home'}
                        className={classes.buttonAboutUs}
                    >   
                        About Us
                    </Button>
                    <div className={classes.gradientDivBackground} />
                </Grid>
            </Grid>
        </div>
    </Container>
)

const ThirdSection = ({classes}) => {

    const isTouchDevice = useMediaQuery('@media (pointer:coarse)');

    return (
        <div className={classes.thirdSection}>
            <Container maxWidth="lg">
                <Carousel
                    {...carouselSettings}
                    showArrows={!isTouchDevice}
                >
                    {
                        clients.map((c, i) =>
                                <ClientCard key={'c-card-'+i} {...c}/>
                        )
                    }
                </Carousel>
            </Container> 
        </div>
    )
}

const Home = () => {
    
    const classes = useStyles();     

    return(
        <>   
            <FirstSection classes={classes}/>
            <SecondSection classes={classes}/>
            <ThirdSection classes={classes} />
        </>
    )
}

export default Home;