import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles( theme =>
  createStyles({
    imgTeam:{
        objectFit: 'contain',
        width:'100%',
        boxShadow: '0.75em 0.75em 0.75em rgba(0, 0, 0, 0.25)',
        zIndex:'-3'
    },
    divTeam:{
        display:'flex',
        position:'relative',
        cursor:'pointer'
    },
    nombrePuestoDivTeam:{
        position:'absolute',
        margin:'0 0 1.5em 1em',
        left:0,
        bottom:0,
        textAlign:'left'
    },
    nombreTeam:{
        color:'white',
        fontWeight: 600,
        lineHeight: '26px'
    },
    puestoTeam:{
        color:'white',
        fontWeight: 'bold'
    },
    titleTeam:{
        color:'black',
        fontWeight: 'bold',
    },
    iconSearchTeam:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color:'white',
        zIndex:'-2',
        filter:'brightness(80%)',
        fontSize: '2.5em'
    },
  })
);

export default useStyles;