import React from 'react';
import Avatar from '@mui/material/Avatar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const arrowStyles = {
    position: "absolute",
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
};

const ButtonArrow = (isPrev, onClickHandler, label) =>(
    <Avatar
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{
            ...arrowStyles,
            left: isPrev && "0.75em",
            right: !isPrev && "0.75em"
        }}
        >
        {isPrev ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
    </Avatar>
    
)

const settings = {
    infiniteLoop: true,
    axis:'horizontal',
    preventMovementUntilSwipeScrollTolerance: true,
    swipeScrollTolerance:30,
    showThumbs:false,
    showIndicators:false,
    showStatus:false,
    renderArrowPrev: (onClickHandler, hasPrev, label) => hasPrev && ButtonArrow(true,onClickHandler, label),
    renderArrowNext: (onClickHandler, hasPrev, label) => hasPrev && ButtonArrow(false,onClickHandler, label),
}

export default settings;