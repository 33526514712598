import teamJuan from '../../../assets/team-juan.webp';
import teamPablo from '../../../assets/team-pablo.webp';
import teamMaureen from '../../../assets/team-maureen.webp';
import teamJorge from '../../../assets/team-jorge.webp';
import teamClaudia from '../../../assets/team-claudia.webp';

const teamList = [
    {
        nombre: 'JUAN',
        texto: 'Juan is a Guatemalan proud of his country and engaged in helping Guatemala reach the next level of prosperity through developing local ecosystems that create wealth by leveraging real estate, entrepreneurship, finance and technology.  In 1998 Juan co-founded Ziprealty in Silicon Valley and went public in the NASDAQ in 2005. Since then he founded Campus Tecnológico (TEC); has been a Director in Banco GyT Continental since 2007;  founded Titon Ideas.  Juan currently leads Gea Capital, investing in local startups with great teams and revolutionary models that can disrupt and service industries in Latin America.',
        img: teamJuan,
        puesto: 'President',
    },
    {
        nombre: 'PABLO',
        texto: 'Lifelong learner, investor and businessman. Pablo has more than ten years of experience in creating and growing companies. He was Co-Founder and Managing Partner of Gestla Ventures (2016); now he is Founder and General Manager of Advanced Management School (2018) and Managing Partner at Gea Capital. ',
        img: teamPablo,
        puesto: 'Managing Partner'
    },
    {
        nombre: 'MAUREEN',
        texto: 'With almost 25 years experience in helping coordinate the process of merging, acquiring and divesting companies for strategic business reasons, Maureen is our M&A Manager at Gea Capital. ',
        img: teamMaureen,
        puesto: 'M&A Manager'
    },
    {
        nombre: 'JORGE',
        texto: 'Graduated from the University of Dallas in 2017 with a BA in Economics and currently an MBA candidate at Acton MBA in Universidad Francisco Marroquin Jorge is our financial analyst.',
        img: teamJorge,
        puesto: 'Financial Analyst'
    },
    {
        nombre: 'CLAUDIA',
        texto: 'Throughout her professional career, Claudia has consolidated work teams with high performance and overcoming challenges in different areas of management, organization, and communication. She currently manages the Incubator and Acceleration Programs at Gea Capital.',
        img: teamClaudia,
        puesto: 'Office and Program Manager'
    },
]

export default teamList;