import { createStyles, makeStyles } from '@mui/styles';

export const imgGridHeight = 524;

const useStyles = makeStyles( theme =>
  createStyles({
        vanaDivFlex:{
            justifyContent:'center',
            display:'flex',
            flexFlow:'column',
            backgroundColor:'white',
            // height:'100%',
            width:'100%'
        },

        logoSection:{
            height: (Math.trunc(imgGridHeight/4)) + 'px',
            alignItems: 'end',
            justifyContent: 'center',
            display:'flex',
        },

        textSection:{
            height:(Math.trunc(imgGridHeight/4)) + 'px',
        },

        text:{
            color: '#54565A',
            fontWeight: 'bold',
            textAlign:'center',
            margin: '3em 1.5em 0 1.5em',
            
        },

        detailsSection:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width:'100%',
            height:(Math.trunc(imgGridHeight/4)) + 'px',
        },

        textVana2:{
            color: '#54565A',
            fontWeight: 'bold',
        },

        divider:{
            marginRight:"-1px",
            background: 'black',
        },

        buttonSection:{
            height:(Math.trunc(imgGridHeight/6)) + 'px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },

        buttonLearnMore : {
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            // margin: 'auto', 
            display: 'fit-content',
            padding: '0.75rem 1.5rem 0.75rem  1.5rem',
        },

        grid:{
            height: imgGridHeight+'px',
            display:'flex', 
            alignItems:'center',
            backgroundColor:'white',
        },

        imgGrid:{
            height: imgGridHeight+'px',
            display:'flex', 
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:'white',
            [theme.breakpoints.down("md")]:{
                height: 'calc((100vw - 6rem)/3*2) ',
            }
        },

        cardImg:{
            width:"100%",
            height:"100%",
            objectFit:"contain"
        },

        detailGrid:{
            textAlign:'center',
            padding: '0 1.5rem 0 1.5rem'
        }

  })
)

export default useStyles;