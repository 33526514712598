import React from 'react';
import { Avatar, Modal, Typography} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh',
    background: 'white',
    boxShadow: '24',
    border:'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem 1.5rem 1.5rem 1.5rem'
  };

const DoneAvatarIcon = () => (
    <Avatar style={{background: 'green'}}>
        <DoneIcon/>
    </Avatar>
)

const CloseAvatarIcon = () => (
    <Avatar style={{background: 'red'}}>
        <CloseIcon/>
    </Avatar>
)

const ModalAlert = ({open, success, message, onClose}) =>(
    <Modal
        open={open}
        onClose={onClose}
    >   
    <>
        <div style={style}>      
            <Typography variant="h6" style={{marginRight:'1.5rem'}}> 
                {message} 
            </Typography>
            {
                success ? <DoneAvatarIcon /> : <CloseAvatarIcon />
            }
        </div>
    </>
    </Modal>
)

export default ModalAlert;