import React from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import DesktopBox from './Components/DesktopBox/DesktopBox';
import MobileBox from './Components/MobileBox/MobileBox';


const appBarStyle = {
    marginTop: '1.5em',
    backgroundColor: 'transparent',
    boxShadow: 'none',
}

const NavBar = ({pages}) => {
    
    const location = useLocation();

    const getPathname = (pathnames) =>{
        switch(true){
            case pathnames.length === 0: return "";
            case pathnames.length === 1: return pathnames[0];
            default: return pathnames[1];
        }
    }

    const pathnames = location.pathname.split('/');
    const pathname = getPathname(pathnames);

    const lightNavbar = ['aboutus', 'investments'].includes(pathname);

    return (
        <AppBar style={appBarStyle} position="absolute">
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <DesktopBox pages={pages} light={lightNavbar}/>
                    <MobileBox pages={pages} light={lightNavbar}/>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;