import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import geaLogo from '../../../../assets/gea-logo.png';
import geaLogoLight from '../../../../assets/gea-logo-white.png';
import { Grid} from '@mui/material';

const styleBox = {
    justifyContent:'end', 
    flexGrow: 1, 
    display: { 
        xs: 'none', 
        md: 'flex' 
    }
}

const DesktopBox = ({pages, light}) => {

    const styleButton = {
        fontWeight: 'bold',
        textAlign: 'center',
        color: light ? 'white' : '#54565A',
        fontSize : '1.25rem',
    }

    const navigate = useNavigate();

    return(
        <Box sx={styleBox}>
            <Grid container>
                <Grid item xs>
                    <div style={{display:'flex', justifyContent:"left"}}>
                        <img 
                            alt="" src={light ? geaLogoLight : geaLogo} 
                            style={{margin:'0.5rem', maxWidth:'160px', cursor:'pointer'}}
                            onClick={() => navigate('/')}
                            />
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <Grid container  direction="row" justifyContent="flex-end">
                        {
                            pages.map((page) => (
                                <Button
                                    style={styleButton}
                                    component={Link}
                                    to={page.to}
                                    key={page.name}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page.name.toUpperCase()}
                                </Button>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DesktopBox;