import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles( theme =>
  createStyles({
    mainDivModal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '70vh',
        background: 'white',
        boxShadow: '24',
        border:'none',
        outline: 'none',
        overflowY:'auto',
        [theme.breakpoints.down("md")]:{
            width: '100%',
        }
    },
    textModalTeam:{
        color: 'black',
        marginBottom:'25px',
        textAlign:'justify',
    },
    textNombreModalTeam:{
        color: 'black',
        fontWeight: 'bold',
        marginBottom:'10px',
    },
    imgModalTeam:{
        width:'100%',
        height: '100%'
    },
    divTextModalTeam:{
        padding:'1.5em 1.5em 1.5em 1.5em',
    },
    closeModalButton:{
        position:'absolute',
        right:'3%',
        top:'3%',
        filter:'brightness(80%)',
        fontSize: '2.5em',
        color:'white'
    }
  })
);

export default useStyles;