import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './AboutUsMat';
import { Container, Grid, Typography, Button } from '@mui/material';
import aboutus31 from '../../assets/aboutus31.webp';
import aboutus32 from '../../assets/aboutus32.webp';
import teamList from './Team/TeamList.jsx';
import Team from './Team/Team';

const FirstSection = ({classes}) => (
    <div className={classes.gradientDiv}>
        <Container maxWidth="lg">
            <Typography variant="h2" className={classes.firstSectionTitle} style={{marginTop:'4.5em'}}> 
                We identify great founders with disruptive technologies.
            </Typography>
            <Typography variant="h4" className={classes.firstSectionTitle} style={{marginTop:'1em'}}> 
                And together strive to solve our region's most pressing needs and generate significant value to society.
            </Typography>
            <Button
                component={Link}
                to={'/contact'}
                key={'contact-us-aboutus'}
                variant={'contained'}
                className={classes.buttonContactUs}
            >
                Contact Us
            </Button>
        </Container>
    </div>
)

const SecondSection = ({classes}) =>(
    <Container maxWidth="lg">
        <Grid
            style={{padding:'4.5rem 0 4.5rem 0'}}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={10} md={10.5}>
                <Typography variant="h4" className={classes.secondSectionTitle}> 
                We invest in smart and talented people solving complex problems using innovative technologies.
                </Typography>
            </Grid>
        </Grid>
    </Container> 
)

const ThirdSection = ({classes}) =>(
    <Container maxWidth="lg" className={classes.containerThirdSection}>
        <Grid
            container
            direction="row"
            alignItems="stretch"
            columnSpacing={{xs:0, md:6}}
            rowSpacing={6}
        >
            <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                <div className={classes.divFlex}>
                    <Typography variant="h6" className={classes.thirdSectionTitle}> 
                        We invest in sectors such as FinTech, PropTech, and Smart Cities, but we are open to exploring innovative businesses that can disrupt industries by leveraging technology.
                        <br/><br/>
                        We believe that innovative and disruptive technologies enable economic development
                        and prosperity by making solutions convenient and affordable.
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                <img alt="" src={aboutus31} className={classes.thirdSectionImg} style={{objectFit:'cover'}}/>
            </Grid>
        </Grid>
    
        <Grid
            container
            direction="row"
            alignItems="center"
            style={{marginTop:'3rem'}}
            columnSpacing={{xs:0, md:6}}
            rowSpacing={6}
        >
            <Grid item xs={12} md={3} style={{textAlign:'center'}}>
                <img alt="" src={aboutus32} className={classes.thirdSectionImg} />
            </Grid>
            <Grid item xs={12} md={9}>
                <div className={classes.divFlex}>
                    <Typography variant="h6" className={classes.thirdSectionTitle}> 
                        We are committed to working closely with our founders and build long-term successful
                        relationships and companies. We help them accelerate their learning curves,
                        make their business models more robust, get more traction and, raise their next
                        rounds of financing to achieve regional success.
                    </Typography>
                </div>
            </Grid>
        </Grid>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
        >
            <Grid item xs={12} md={8} style={{marginTop:'3em'}}>
                <div className={classes.divFlex}>
                    <Typography variant="h6" className={classes.thirdSectionTitle} > 
                        We welcome industry expert co-investors that make our board more robust and help us guide the company to regional success.
                        <br/>
                        We offer an incubation and also an acceleration program for our portfolio companies to improve their chances of success and add value to the founders and the companies we invest in.
                    </Typography>
                </div>
            </Grid>
        </Grid>

    </Container>
)

const FourthSection = ({classes}) => (
    
    <Container maxWidth="lg" className={classes.fourthSectionContainer}>
        <Typography variant="h2" className={classes.titleTeam} > 
            Meet our Team.
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
        >   
            {   
                teamList.map( (t,index) => 
                    <Grid key={'team-'+index}  item xs={12} sm={6} md={4}>
                        <Team {...t} classes={classes} />
                    </Grid>
                )  
            }
        </Grid>
    </Container>
)

const AboutUs = () =>{

    const classes = useStyles();   

    return(
        <>
            <FirstSection classes={classes}/>
            <SecondSection classes={classes}/>
            <ThirdSection classes={classes}/>
            <FourthSection classes={classes}/>
        </>
    );
}
export default AboutUs;