import { createStyles, makeStyles } from '@mui/styles';
import backgroundContactForm from '../../assets/background-contact-form.webp';

const useStyles = makeStyles( theme =>
  createStyles({
    contactForm:{
        margin: '9rem 0 0 0',
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 130, 202, 0.5) 100%), url(${backgroundContactForm})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    titleContactForm:{
        margin: '3rem 0 0 0',
        color: 'white',
    },
    inputContactForm:{
        margin: '0.75rem 0 0 0',
        background: 'rgba(255, 255, 255, 0.3)',
    },
    input: {
        color: 'white'
    },
    sendContactForm:{
        margin: '0.75rem 0 3rem 0',
        padding: '0.75rem 3rem 0.75rem 3rem',
        background: 'rgba(0, 130, 202, 0.75)',
        color:'white',
    }
  })
);



export default useStyles;