import React, { useState } from 'react';
import useStyles from './ContactMat';
import { Container, Typography, Button, FormHelperText } from '@mui/material';
import InputField from './InputField';
import ModalAlert from './ModalAlert/ModalAlert';
import axios from 'axios'; 
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ModalLoading from './ModalLoading/ModalLoading';

const feedBacks = {
    short: "Too short!",
    long: "Too long",
    required: "Required",
    email: "Invalid Mail.",
    numberInvalid: "Invalid Number"
}

const ContactFormSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(2, feedBacks.short)
      .max(50, feedBacks.long)
      .required(feedBacks.required),
    company: Yup.string()
      .min(2, feedBacks.short)
      .max(50, feedBacks.long)
      .required(feedBacks.required),
    industry: Yup.string()
      .min(2, feedBacks.short)
      .max(50, feedBacks.long)
      .required(feedBacks.required),
    phone: Yup.string().matches(/^\d+$/, feedBacks.numberInvalid)
      .min(8, feedBacks.short)
      .max(15, feedBacks.long)
      .required(feedBacks.required),
    email: Yup.string().email(feedBacks.email).required(feedBacks.required),
    msg: Yup.string()
      .min(2, feedBacks.short)
      .max(50, feedBacks.long)
      .required(feedBacks.required),
    }
);

const ContactForm = ({classes, form, setInput, sendForm, errors, loading}) =>(
    <div className={classes.contactForm}>
        <Typography variant="h2" className={classes.titleContactForm} > 
            Let's get in touch
        </Typography>
        <Container maxWidth="md" className={classes.containerThirdSection}> 
            <InputField
                required
                id="outlined-fullname"
                label="Full Name"
                inputProps={{ style: { color: 'white'}}}
                InputLabelProps={{style: { color: 'white' },}}
                className={classes.inputContactForm}
                variant="outlined"  
                fullWidth
                name="fullname"
                value={form.fullname}
                onChange={setInput}
                error={errors.fullname}
            />
            {
                errors.fullname &&
                <FormHelperText error> 
                    {errors.fullname}
                </FormHelperText>
            }
            <InputField
                required
                id="outlined-Email"
                label="Email"
                inputProps={{ style: { color: 'white'}}}
                InputLabelProps={{style: { color: 'white' },}}
                className={classes.inputContactForm}
                variant="outlined"  
                fullWidth
                name="email"
                value={form.email}
                onChange={setInput}
                error={errors.email}
            />
            {
                errors.email &&
                <FormHelperText error> 
                    {errors.email}
                </FormHelperText>
            }
            <InputField
                required
                id="outlined-Company"
                label="Company"
                inputProps={{ style: { color: 'white'}}}
                InputLabelProps={{style: { color: 'white' },}}
                className={classes.inputContactForm}
                variant="outlined"  
                fullWidth
                name="company"
                value={form.company}
                onChange={setInput}
                error={errors.company}
            />
            {
                errors.company &&
                <FormHelperText error> 
                    {errors.company}
                </FormHelperText>
            }
            <InputField
                required
                id="outlined-Industry"
                label="Industry"
                inputProps={{ style: { color: 'white'}}}
                InputLabelProps={{style: { color: 'white' },}}
                className={classes.inputContactForm}
                variant="outlined"  
                fullWidth
                name="industry"
                value={form.industry}
                onChange={setInput}
                error={errors.industry}
            />
            {
                errors.industry &&
                <FormHelperText error> 
                    {errors.industry}
                </FormHelperText>
            }
            <InputField
                required
                id="outlined-Phone"
                label="Phone Number"
                inputProps={{ style: { color: 'white'}}}
                InputLabelProps={{style: { color: 'white' },}}
                className={classes.inputContactForm}
                variant="outlined"  
                fullWidth
                name="phone"
                value={form.phone}
                onChange={setInput}
                error={errors.phone}
            />
            {
                errors.phone &&
                <FormHelperText error> 
                    {errors.phone}
                </FormHelperText>
            }
            <InputField
                required
                id="outlined-msg"
                label="Message"
                inputProps={{ style: { color: 'white'}}}
                InputLabelProps={{style: { color: 'white' },}}
                className={classes.inputContactForm}
                variant="outlined"  
                fullWidth
                multiline
                rows={6}
                name="msg"
                value={form.msg}
                onChange={setInput}
                error={errors.msg}
            />
            {
                errors.msg &&
                <FormHelperText error> 
                    {errors.msg}
                </FormHelperText>
            }
            <Button
                key={'send-contact-form'}
                variant={'contained'}
                className={classes.sendContactForm}
                onClick={sendForm}
                loading={loading}
            >
                Send
            </Button>
        </Container>
    </div>
)

const initialForm = {
    fullname: "",
    email: "",
    company: "",
    industry: "",
    phone: "",
    msg: "",
}

const closedModal = {
    open: false,
    message: '',
    success: false
}

const Contact = () => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const form = useFormik({
        initialValues: initialForm,
        onSubmit: values => sendForm(values),
        validationSchema: ContactFormSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const [modal, setModal] = useState(closedModal);

    const resetForm = () => form.resetForm();

    const sendForm = (validatedForm) =>{
        setLoading(true);
        axios.post(
            process.env.REACT_APP_API_CONTACT_ENTRYPOINT,
            {mailContent:validatedForm}
        )
        .then(
            (res) =>{
                showModalAlert('Formulario enviado con éxito.', true);
                resetForm();
            }
        )
        .catch((err) => {
            showModalAlert('Falló envío de formulario', false);
        }).finally(() => setLoading(false));    
    }

    const showModalAlert = (message, success) =>{
        setModal({
            open:true,
            message: message,
            success: success
        })
    }

    const hideModal = () => setModal(closedModal);
    
    const handleChange = (e) => {
        form.setFieldError(e.target.name, undefined);
        form.handleChange(e)
    }
    
    return(
        <>
            <ContactForm 
                classes={classes} 
                form={form.values} 
                setInput={handleChange}
                sendForm={form.handleSubmit}
                errors={form.errors}
                loading={loading}
            />
            <ModalLoading loading={loading} />
            <ModalAlert {...modal} onClose={()=>hideModal()}/>
        </>
    ) 
}

export default Contact;