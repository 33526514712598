import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Grid, Typography, Button } from '@mui/material';
import useStyles from './FooterMat';
import geaLogoFotter from '../../assets/gea-logo-footer.png';
import linkedinLogoFotter from '../../assets/linkedin-logo-footer.png';
import instagramLogoFotter from '../../assets/instagram-logo-footer.png';

const FirstSection = ({classes}) => (
    <div className={classes.firstSectionDiv}>
        <Container maxWidth="lg" className={classes.containerThirdSection}> 
            <Grid
                style={{padding:'3rem 0 3rem 0'}}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12} md={6} className={classes.gridItemCenter}>
                    <Typography variant="h5" className={classes.footerText}> 
                        Let's fund your ideas.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItemCenter}>
                    <Button
                        component={Link}
                        to={'contact'}
                        key={'contact-us-home'}
                        variant={'contained'}
                        className={classes.buttonContactUs}
                    >
                        Contact Us
                    </Button>
                </Grid>
            </Grid>
        </Container>
    </div>
)

const SecondSection = ({classes, pages}) =>(
    
    <div className={classes.secondSectionDiv}>
        <Container maxWidth="lg" className={classes.containerThirdSection}> 
            <Grid
                style={{padding:'3rem 0 3rem 0'}}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={6}
            >
                <Grid 
                    item xs={6} 
                    sm={4} 
                    className={classes.gridItemCenter} 
                    order={{ xs: 2, sm: 1 }}
                >
                    <img alt="" src={geaLogoFotter} className={classes.geaLogoFooter}/>
                    {/* <img alt="" src={geaLogoFotter}/> */}
                </Grid>
                <Grid 
                    item xs={12} 
                    sm={4} 
                    className={classes.gridItemCenterColumn}
                    order={{ xs: 1, sm: 2 }}
                >
                    {
                        pages.map((page) => (
                            <Button
                                className={classes.buttonFooter}
                                component={Link}
                                to={page.to}
                                key={page.name}
                            >
                                {page.name}
                            </Button>
                        ))
                    }
                </Grid>
                <Grid 
                    item 
                    xs={6} 
                    sm={4} 
                    className={classes.gridItemCenter}
                    order={{ xs: 3, sm: 3 }}
                >
                    <a href="https://www.instagram.com/gea.capital">
                        <img 
                            alt="" 
                            src={instagramLogoFotter} 
                            style={{cursor:'pointer', maxHeight:'50px', marginRight:'1.5em'}}
                            
                        />
                    </a>
                    <a href="https://www.linkedin.com/company/geacapital">
                        <img 
                            alt="" 
                            src={linkedinLogoFotter} 
                            style={{cursor:'pointer', maxHeight:'50px'}}
                        />
                    </a>
                </Grid>
            </Grid>
        </Container>
    </div>
)

const Footer = ({pages}) =>{

    const classes = useStyles();

    const location = useLocation();

    const getPathname = (pathnames) =>{
        switch(true){
            case pathnames.length === 0: return "";
            case pathnames.length === 1: return pathnames[0];
            default: return pathnames[1];
        }
    }

    const pathnames = location.pathname.split('/');
    const pathname = getPathname(pathnames);

    const showFirstSection = !['contact'].includes(pathname);

    return(
        <footer style={{paddingTop:'3em'}}>
            {showFirstSection &&<FirstSection classes={classes}/>}
            <SecondSection classes={classes} pages={pages}/>
        </footer>
    ) 
}

export default Footer;