import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './InvestmentsMat';
import { Container, Grid, Typography, Button} from '@mui/material';
import investments31 from '../../assets/investments31.webp';
import fichaVana from '../../assets/vana-investments.png';
import fichaInbio from '../../assets/inbio-investments.png';
import fichaZavvie from '../../assets/zavvie-investments.webp';
import fichaLiif from '../../assets/liff-investments.png';

const FirstSection = ({classes}) => (
    <div className={classes.gradientDiv}>
        <Container maxWidth="lg">
            <Typography variant="h2" className={classes.firstSectionTitle} style={{marginTop:'4.5em'}}> 
                We only invest if we can lead or co-lead rounds.
            </Typography>
            <Typography variant="h4" className={classes.firstSectionTitle} style={{marginTop:'1em'}}> 
                We won't just write a check. We ask for board seats and dedicate time to secure our founder's success actively.
            </Typography>
            <Button
                component={Link}
                to={'/contact'}
                key={'contact-us-home'}
                variant={'contained'}
                className={classes.buttonContactUs}
            >
                Contact Us
            </Button>
        </Container>
    </div>
)

const SecondSection = ({classes}) =>(
    <Container maxWidth="lg">
        <Grid
            style={{padding:'4.5rem 0 4.5rem 0'}}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={10} md={10.5}>
                <Typography variant="h4" className={classes.secondSectionTitle}> 
                We invest in early-stage companies from pre-seed to series A. Our ticket size usually stands between $250,000 to $2 million.  We participate in multiple rounds if possible.
                </Typography>
            </Grid>
        </Grid>
    </Container>
)

const ThirdSection = ({classes}) =>(
    <Container maxWidth="lg" className={classes.containerThirdSection}>    
        <Grid
            container
            direction="row"
            alignItems="center"
            columnSpacing={{xs:0, md:6}}
            rowSpacing={6}
        >
            <Grid item xs={12} md={6}>
                <img alt="" src={investments31} className={classes.thirdSectionImg} />
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={classes.divFlex}>
                    <Typography variant="h6" className={classes.thirdSectionTitle} style={{marginTop:'3em'}}> 
                    Gea Capital invests its own funds and relies on a solid network of talented investors and successful funds to invest with Us and add value to the founders.  We welcome industry expert co-investors that make our board more robust and help us guide the company to regional success.
                    </Typography>
                </div>
            </Grid>
        </Grid>
    </Container>
)

const fichasInvestments = [
    fichaVana, fichaInbio, fichaZavvie, fichaLiif,
]

const FourthSection = ({classes}) => (
    <Container maxWidth="lg" style={{paddingTop:'1.5em'}}>    
        <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
        >
            {fichasInvestments.map(f => 
                <Grid item xs={6} md={3}>
                    <img alt="" src={f} className={classes.fichas}/>
                </Grid>
            )}
        </Grid>
    </Container>
)


const Investments = () =>{

    const classes = useStyles();   

    return(
        <>
            <FirstSection classes={classes}/>
            <SecondSection classes={classes}/>
            <ThirdSection classes={classes}/>
            <FourthSection classes={classes}/>
        </>
    );
}
export default Investments;